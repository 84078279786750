<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-web-box"></v-icon> &nbsp;
        Website
        <v-spacer></v-spacer>
        <v-btn icon="$close" variant="text" @click="$router.go(-1)"></v-btn>
      </v-card-title>
      <v-card flat>

        <v-card-text style="position: relative;">
          <v-text-field
              v-model="formData.title"
              label="Title1"
              prepend-icon="mdi-text"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <v-textarea
              v-model="formData.description"
              label="Beschreibung1"
              prepend-icon="mdi-text"
              outlined
              dense
              filled
              color="primary"
          ></v-textarea>


          <v-text-field
              v-model="formData.title2"
              label="Title2"
              prepend-icon="mdi-text"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <v-textarea
              v-model="formData.description2"
              label="Beschreibung2"
              prepend-icon="mdi-text"
              outlined
              dense
              filled
              color="primary"
          ></v-textarea>


          <v-text-field
              v-model="formData.pdf_title"
              label="Pdf Title"
              prepend-icon="mdi-text"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <v-text-field
              v-model="formData.pdf_bottom"
              label="Pdf Bottom"
              prepend-icon="mdi-text"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <v-file-input
              label="Web Horizontal"
              prepend-icon="mdi-image-edit"
              accept="image/*"
              outlined
              dense
              @change="handleFileChange('web_image', $event)"
          ></v-file-input>

          <v-file-input
              label="Web Vertikal"
              prepend-icon="mdi-image-edit"
              accept="image/*"
              outlined
              dense
              @change="handleFileChange('web_v_image', $event)"
          ></v-file-input>

          <v-color-picker
              v-model="formData.web_background"
              v-if="colorShow"
              label="Farbe"
              mode="hex"
              placeholder="Farbe"
              prepend-icon="mdi-palette"
              outlined
              dense
              filled
              style="position: absolute; z-index: 9999; top:40%; left:20%;"
              @mouseleave="colorShow = false"
          ></v-color-picker>

          <v-text-field
              v-model="formData.web_background"
              label="Farbe"
              placeholder="Farbe"
              prepend-icon="mdi-palette"
              outlined
              dense
              filled
              :bg-color="formData.web_background"
              @focusin="colorShow = true"
          ></v-text-field>

          <v-select
              v-model="formData.template_id"
              :items="templates"
              item-title="name"
              item-value="id"
              label="Vorlage"
              placeholder="Vorlage auswählen"
              prepend-icon="mdi-palette-swatch"
              outlined
              dense
              filled
              color="primary"
              :multiple="true"
          ></v-select>


          <v-checkbox
              v-model="formData.scroll"
              :false-value="0"
              :true-value="1"
              label="Scrollen"
              prepend-icon="mdi-arrow-horizontal-lock"
              outlined
              dense
              filled
              color="primary"
          >
          </v-checkbox>

          <v-slider v-model="formData.scroll_speed" :label="`Scroll Geschwindigkeit ${formData.scroll_speed/1000} sec`" min="3000" max="30000" step="1"></v-slider>


          <v-col
            class="text-center">
            <v-btn @click="save"
                   :disabled="false"
                   color="primary"
                   class="text-right"
            >
              Speichern
            </v-btn>
            </v-col>

        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
</template>

<script>
  export default {
    data: () => ({
      colorShow: false,
      formData: {
        scroll_speed: 3000,
      },
    }),
    mounted() {
      this.$store.dispatch('template/fetchTemplates', this.$store.state.overrideHotelId);


      this.$store.dispatch('website/fetchWebsite', this.$route.params.id).then(() => {
        this.formData = this.$store.state.website.website;
        if(this.formData.website_templates) {
          this.formData.template_id = this.formData.website_templates.map(t => t.template_id);
        }
        if(this.formData && !this.formData.scroll_speed) {
          this.formData.scroll_speed = 3000;
        }
      });
    },
    methods: {
      handleFileChange(propertyName, event) {
        const files = event.target.files;
        if (files && files.length > 0) {
          this.formData[propertyName] = files[0];
        } else {
          this.formData[propertyName] = null;
        }
      },
      save() {
        this.$store.dispatch('website/create', {...this.formData, 'hotelId': this.$store.state.overrideHotelId}).then(() => {
            this.$router.push("/");
        });
      },
    },
    computed: {
      templates() {
        return this.$store.state.template.templates;
      },
    }
  };
</script>
