<template>
  <div v-if="this.website" :style="`background-color: ${this.website.web_background}; height:100%; width:100%`">
    <div class="text-center">
      <img :src="this.baseUrl + this.website.web_image" alt="web_image" style="width:100%; max-width: 900px;" v-if="this.website.web_image && this.orientation === 'h'">
      <img :src="this.baseUrl + this.website.web_v_image" alt="web_image" style="width:100%; max-width: 540px;" v-if="this.website.web_v_image && this.orientation === 'v'">
    </div>

    <v-container>
      <v-row>
        <div class="text-center" style="width: 100%;">
          <h2>{{this.website.title}}</h2>
        </div>
      </v-row>
      <v-row>
        <v-col>
          <div class="col-6 text-center" style="word-wrap: break-word;">
            <p>{{this.website.description}}</p>
          </div>
        </v-col>
      </v-row>
    </v-container>



    <v-container v-if="this.website.website_templates">
      <v-row v-for="kw in this.website.website_templates" :key="kw.id" class="mt-6">
        <div class="text-center" style="width: 300px; margin:0 auto;display: flex;align-items: center;" v-if="kw.template && kw.template.kw[0]">
          <router-link :to="`/online/${this.$route.params.id}/${kw.template.id}`">
            <v-alert
                to="/"
                v-model="alert"
                border="start"
                color="black"
                variant="tonal"
                style="min-width: 300px;"
            >
              <b class="text-center">{{kw.template.name}}</b>
              <br>
              <b>{{kw.template.kw[0].name}}</b>
              <span class="ml-4">{{kw.template.kw[0].showstartdate ? kw.template.kw[0].showstartdate : kw.template.kw[0].startdate}}
                - {{kw.template.kw[0].showenddate ? kw.template.kw[0].showenddate : kw.template.kw[0].enddate}}</span>
            </v-alert>
          </router-link>
          <v-btn
              color="surface-variant"
              variant="text"
              :to="`/pdf/${this.$route.params.id}/${kw.template.id}`"
              icon="mdi-printer"
          >
          </v-btn>
        </div>
      </v-row>

      <v-row>
        <div class="text-center" style="width: 100%;">
          <h2>{{this.website.title2}}</h2>
        </div>
      </v-row>
      <v-row>
        <v-col>
          <div class="col-6 text-center" style="word-wrap: break-word;">
            <span>{{this.website.description2}}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>
<script>

export default {
  data: () => ({
    orientation: null,
    slides: 0,
    slide: 1,
    id: 0,
  }),
  mounted() {
    this.orientation = window.innerWidth > window.innerHeight ? 'h' : 'v';

    this.$store.dispatch('website/fetchWebsite', this.$route.params.id);
  },
  methods: {
    generateReport(templateId) {
      const refName = `html2Pdf${templateId}`;
      const pdfComponent = this.$refs[refName][0];

      if (pdfComponent && typeof pdfComponent.generatePdf === 'function') {
        pdfComponent.generatePdf();
      } else {
        console.error(`generatePdf is not a function on ref: ${refName}`);
      }
    },
    generateReportPrint(templateId) {
      const refName = `html2Pdf${templateId}print`;
      const pdfComponent = this.$refs[refName][0];

      if (pdfComponent && typeof pdfComponent.generatePdf === 'function') {
        pdfComponent.generatePdf();
      } else {
        console.error(`generatePdf is not a function on ref: ${refName}`);
      }
    }
  },
  computed: {
    website() {
      return this.$store.state.website.website;
    },
    data() {
      return this.$store.state.canvas;
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
};
</script>
