<template>
  <div v-if="this.website" :style="`background-color: ${this.website.web_background}; min-height:100vh; width:100%; overflow: hidden;`">
    <div class="text-center">
      <router-link :to="`/online/${this.$route.params.id}`">
      <img :src="this.baseUrl + this.website.web_image" alt="web_image" style="width:100%; max-width: 900px;" v-if="this.website.web_image && this.orientation === 'h'">
      <img :src="this.baseUrl + this.website.web_v_image" alt="web_image" style="width:100%; max-width: 540px;" v-if="this.website.web_v_image && this.orientation === 'v'">
      </router-link>
    </div>
    <div class="justify-center">
      <SlideshowOnline :id="this.$route.params.canvasId" v-if="this.$route.params.canvasId" :website="this.website"/>
    </div>
  </div>
</template>
<script>
import SlideshowOnline from "@/views/components/Template/SlideshowOnline";

export default {
  components: {SlideshowOnline},
  data: () => ({
    orientation: null,
    slides: 0,
    slide: 1,
    id: 0,
  }),
  mounted() {
    this.orientation = window.innerWidth > window.innerHeight ? 'h' : 'v';

    this.$store.dispatch('website/fetchWebsite', this.$route.params.id);
  },
  methods: {

  },
  computed: {
    website() {
      return this.$store.state.website.website;
    },
    data() {
      return this.$store.state.canvas;
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
};
</script>
